import React, { Fragment } from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';

import { hideModalAction } from '../../../redux/actions';
import DemoContent from './DemoContent';
import './ReleaseNotesModalContent.scss';

// Redux
//=================================================================================================
const mapStateToProps = state => ({
    modal: state.modal,
    modalType: state.modal.currentModal.modalType,
    buttons: state.modal.currentModal.modalButtons,
    currentModal: state.modal.currentModal
});

const mapDispatchToProps = dispatch => ({
    hideModalAction: () => dispatch(hideModalAction())
});

// Types
//=================================================================================================
type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

// Component
//=================================================================================================
const StandardModalContent = (props: Props) => {
    const versionNumber = process.env.REACT_APP_VERSION
        ? process.env.REACT_APP_VERSION.substring(0, process.env.REACT_APP_VERSION.indexOf(':'))
        : '';
    const environment = process.env.REACT_APP_VERSION
        ? process.env.REACT_APP_VERSION.substring(process.env.REACT_APP_VERSION.indexOf(':') + 1)
        : '';
    let body = props.currentModal && props.currentModal.content && props.currentModal.content.body;
    if (typeof body === 'string') {
        body = [body];
    }

    return (
        <Fragment>
            <ModalHeader toggle={() => props.hideModalAction()}>What's New?</ModalHeader>
            <ModalBody>
                {/* Add Content Here - copy paste stuff from demo content */}
                <DemoContent />
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={() => {
                        props.hideModalAction();
                    }}
                >
                    Got it!
                </Button>
            </ModalFooter>
        </Fragment>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StandardModalContent);
