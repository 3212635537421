import React, { FunctionComponent, useEffect, useState } from 'react';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import {
    Alert,
    Button,
    ButtonProps,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Input,
    InputProps,
    Label,
    Row
} from 'reactstrap';
import { isPasswordValid } from '../../../../utils';
import './Account.scss';
import CustomInput from '../../../../components/Form/Input/Input';
import ButtonRipple from 'components/Buttons/ButtonRipple/ButtonRipple';

type Props = {
    passwordChange: any;
    changePasswordAction: any;
    changePasswordInitialiseAction: any;
};

const AccountPassword: FunctionComponent<Props> = (props: Props) => {
    useEffect(() => {
        setPasswordInputConfirmValue('');
        setPasswordInputNewValue('');
        setPasswordInputCurrentValue('');
    }, [props.passwordChange.updated]);

    const [passwordInputCurrentValue, setPasswordInputCurrentValue] = useState('');
    const [passwordInputNewValue, setPasswordInputNewValue] = useState('');
    const [passwordInputConfirmValue, setPasswordInputConfirmValue] = useState('');

    const shouldInitialiseChangePassword = () => {
        if (props.passwordChange.isError || props.passwordChange.updated) {
            props.changePasswordInitialiseAction();
        }
        return true;
    };

    // const passwordInputs: { [a: string]: InputProps } = {
    const passwordInputs = [
        {
            label: 'Current password',
            id: 'current-password',
            type: 'password',
            value: passwordInputCurrentValue,
            onChange: e =>
                shouldInitialiseChangePassword() && setPasswordInputCurrentValue(e.target.value),
            valid: isPasswordValid(passwordInputCurrentValue),
            disabled: props.passwordChange.requestInProcess
        },
        {
            label: 'New password',
            id: 'new-password',
            type: 'password',
            value: passwordInputNewValue,
            onChange: e =>
                shouldInitialiseChangePassword() && setPasswordInputNewValue(e.target.value),
            valid: isPasswordValid(passwordInputNewValue),
            disabled: props.passwordChange.requestInProcess
        },
        {
            label: 'Confirm password',
            id: 'confirm-password',
            type: 'password',
            value: passwordInputConfirmValue,
            onChange: e =>
                shouldInitialiseChangePassword() && setPasswordInputConfirmValue(e.target.value),
            valid:
                isPasswordValid(passwordInputConfirmValue) &&
                passwordInputConfirmValue === passwordInputNewValue,
            disabled: props.passwordChange.requestInProcess
        }
    ];

    return (
        <Card>
            <CardBody>
                <h4 style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Change Password</span>
                </h4>

                {passwordInputs.map(input => (
                    <FormGroup className="flex-grow-1 w-100" key={input.id}>
                        <CustomInput
                            id={input.id}
                            label={input.label}
                            onChange={input.onChange}
                            type={input.type}
                            value={input.value}
                        />
                    </FormGroup>
                ))}
                <Alert
                    children={props.passwordChange.currentError}
                    color="danger"
                    isOpen={props.passwordChange.isError}
                />
                <Alert color="success" isOpen={props.passwordChange.updated}>
                    Password was successfully updated
                </Alert>

                <Row className="justify-content-end">
                    <Col xs="auto">
                        <ButtonRipple
                            className="ripple-primary"
                            disabled={
                                !!passwordInputs.find(input => !input.valid) ||
                                props.passwordChange.isError ||
                                props.passwordChange.requestInProcess
                            }
                            onClick={() => {
                                props.changePasswordAction({
                                    confirm: passwordInputConfirmValue,
                                    current: passwordInputCurrentValue,
                                    new: passwordInputNewValue
                                });
                            }}
                        >
                            Save
                        </ButtonRipple>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};
export default AccountPassword;
