import { cellCurrency, cellNumber, cellValue } from 'components/ReactTable/utils';
import { ColumnDef } from '@tanstack/table-core';

type dataValue = Record<string, string | number>;
type GenerateColumns = () => (ColumnDef<dataValue, string> | ColumnDef<dataValue, number>)[];

export const generateColumns: GenerateColumns = () => [
  {
    id: 'code',
    accessorKey: 'code',
    header: 'Code',
    cell: (row) => <span>{cellValue(row)}</span>
  },
  {
    id: 'count',
    accessorKey: 'count',
    header: 'Attempts',
    cell: cellNumber,
    meta: { align: 'right' }
  },
  {
    id: 'revenue',
    accessorKey: 'revenue',
    header: 'Revenue',
    cell: cellCurrency,
    meta: { align: 'right' }
  }
];
