import React from 'react';
import { legendColors } from './WorldMap';

type Props = {};

const Legend: React.FC<Props> = () => {
    return (
        <div className="world-map-legend" data-testid="world-map-legend">
            <h5>Global Code Attempts</h5>

            <div className="scale">
                <div className="scale-labels">
                    <div className="scale-from">0%</div>
                    <div className="scale-to">100%</div>
                </div>

                <div className="scale-track" />
            </div>
        </div>
    );
};

export default Legend;
