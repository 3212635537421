export const processDataToDownload = (tableHeaders, tableRows, tableFooters, table) => {
    let currencyKeys = ['revenue', 'discountValue', 'averageOrderValue'];
    let percentKeys = ['validPercent', 'conversionRate'];
    let headers = tableHeaders[0].headers;
    let footers = tableFooters[0]?.headers;

    const csvHeaders = headers.map((header) => header.column.columnDef.header);
    const csvFooters = footers?.map((footerColumn) => {
        const { header, footer } = footerColumn.column.columnDef;
        const footerValue = (typeof footer === 'function') ? footer({ table, header, column: footerColumn.column }) : footer;
        return footerValue || '';
    }) || [];

    let csvData = tableRows.map((row) =>
        row.getAllCells().map((cell) => {
            const downloadFunction = cell?.column?.columnDef?.download;
            const cellFunction = cell?.column?.columnDef?.cell || ((row) => row?.getValue());
            const formatFunction = downloadFunction || cellFunction;
            const value = cell.getValue();
            return formatFunction(cell);
        })
    ) || [];

    if (csvData.length > 0) {
        csvData.push(csvFooters);
    }

    return { csvHeaders, csvData };
};
