import classNames from 'classnames';

import List from 'components/List/List';
import React, { useState } from 'react';
import './Menu.scss';

type Anchor = number | string;

interface Props {
    listItems: any[];
    className?: string;
    setOpen;
    anchor?: { top?: Anchor; bottom?: Anchor; left?: Anchor; right?: Anchor };
}

const Menu = ({ className, listItems, setOpen, anchor }: Props): JSX.Element => {
    const { top = 'auto', left = 'auto', bottom = 'auto', right = 'auto' } = anchor || {};

    const [subMenuPositions, setSubMenuPositions] = useState({});
    const [subHover, setSubHover] = useState({});

    const subMenus = listItems.filter((item) => !!(item.subItems && item.subItems.length));

    return (
        <div className="menu-container">
            <div className={classNames('menu', className)} style={{ top, left, bottom, right }}>
                <List
                    items={listItems}
                    setOpen={setOpen}
                    setSubMenuPositions={setSubMenuPositions}
                />
            </div>

            {subMenus.map(({ label, subItems: items }) => {
                const { top, left, right, hover } = subMenuPositions?.[label] || {};
                const localHover = subHover[label];

                return (
                    <div
                        className={classNames(
                            'menu',
                            'menu-sub',
                            hover || localHover ? 'hover' : ''
                        )}
                        id={label}
                        key={label}
                        onMouseEnter={() => {
                            setSubHover((prev) => ({ ...prev, [label]: true }));
                        }}
                        onMouseLeave={() => {
                            setSubHover((prev) => ({ ...prev, [label]: false }));
                        }}
                        style={{ top: (top || 0) + 2, right: right || 0 }}
                    >
                        <List items={items} setOpen={setOpen} />
                    </div>
                );
            })}
        </div>
    );
};

export default Menu;
