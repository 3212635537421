import React, { FunctionComponent } from 'react';
import { Col, ColProps } from 'reactstrap';
import { any } from 'prop-types';

const CustomCol: FunctionComponent<ColProps> = (props: ColProps) => {
    const newProps = {
        ...props,
        widths: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
    };
    //@ts-ignore
    return <Col {...newProps} />;
};

export default CustomCol;
