import React, { Suspense, useEffect, Fragment, lazy } from 'react';
import { useDispatch, useSelector as uSel } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router';
import ReactGA from 'react-ga';
import isEmpty from 'lodash/isEmpty';
import { Switch } from 'react-router-dom';

import { IAppStoreState as S } from 'redux/interfaces';
import { statusAction, hideModalAction } from './redux/actions';
import { history } from './redux/store';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Modal from './components/Modal/Modal';
import Spinner from './components/Spinner/Spinner';
import Dashboard from './Dashboard';
import { LoginAs } from './pages/LoginAs';
import ResetPassword from './pages/ResetPassword';
import FAB from './components/FAB/FAB';
import useTimeout from './utils/customHooks/useTimeout';
import useWake from './utils/customHooks/useWake';
import { Cell, RowData } from '@tanstack/table-core';

const Maintenance = lazy(() => import('./pages/Maintenance/Maintenance'));

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    align?: string;
    from?: string[];
    wrapText?: string;
    onCellClick?: (cell: Cell<Record<string, string | number>, unknown>, index: number) => void;
  }
}

history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

const AppRun = (): JSX.Element => {
    const dispatch = useDispatch();
    const modalCurrent = uSel((state: S) => state.modal.currentModal);
    const modalQueue = uSel((state: S) => state.modal.executionQueue);
    const pathname = uSel((state: any) => state?.router?.location?.pathname);
    
    // Check if logged in constantly
    useTimeout(() => {
      dispatch(statusAction());
    }, 60 * 1000);
  
    // Check if logged in when changing page
    useEffect(() => {
        dispatch(statusAction());
    }, [pathname]);

    // Check if logged when waking from sleep
    useWake(() => { dispatch(statusAction()); });
    
    // On page change
    useEffect(
        () =>
            history.listen(() => {
                const modalCount = modalQueue?.length || 0 + (isEmpty(modalCurrent) ? 0 : 1);
                for (var i = 0; i < modalCount; i++) hideModalAction();
            }),
        []
    );

    return (
        <Fragment>
            <ErrorBoundary>
                <Suspense fallback={<Spinner large />}>
                    <Dashboard />
                </Suspense>
                <Modal />
                <FAB />
            </ErrorBoundary>
        </Fragment>
    );
};

const App = (): JSX.Element => {
    const maintenance = false;

    if(maintenance) return (
      <Suspense fallback={<Fragment />}>
        <Maintenance />
      </Suspense>
    );

    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route component={LoginAs} path="/loginas" />
                <Route component={ResetPassword} path="/account/reset-password" />
                <Route component={AppRun} />
            </Switch>
        </ConnectedRouter>
    );
};
export default App;
