import React, { Fragment, Suspense, useEffect } from 'react';
import { useDispatch, useSelector as uSel } from 'react-redux';
import { addModalAction } from 'redux/actions';
import { IAppStoreState as S } from 'redux/interfaces';
import { Redirect, Route, Switch } from 'react-router-dom';
import cx from 'classnames';
import { Container } from 'reactstrap';

import { IRouteItem } from 'index.types';
import Layout from 'components/layout/Layout';
import Spinner from 'components/Spinner/Spinner';
import Context from './home/Context/Context';
import CodesContext from './home/CodesContext/CodesContext';
import Unauthorised from './Unauthorised';
import Account from './views/Account';
import SmarterCodes from './views/SmarterCodes';
import NoPermissions from './views/SmarterCodes/NoPermissions/NoPermissions';

const Dashboard = (): JSX.Element => {
    const dispatch = useDispatch();
    const smartercodes = uSel((state: S) => state.dashboard?.access?.smartercodes);
    const loggedIn = uSel((state: S) => state.auth.loggedIn);
    const userLoaded = uSel((state: S) => state.user.loaded);
    const isLoginModalOpen = uSel(
        (state: S) => state.modal?.currentModal?.modalType === 'loginModal'
    );
    const dim = uSel((state: S) => state.dashboard.dim.verdict);
    const checkingStatus = uSel((state: S) => state.auth.checkingStatus);
    const hasChecked = uSel((state: S) => state.auth.hasChecked);
    const pathname = uSel((state: any) => state?.router?.location?.pathname);

    const routes: IRouteItem[] = smartercodes
        ? [
            { path: '/account', component: Account, key: 4 },
            { path: '', component: SmarterCodes, key: 3, hasContext: true },
          ]
        : [{ path: '/no-permissions', component: NoPermissions, key: 50 }];
    const filteredRoutes = routes.filter((route) => pathname.startsWith(route.path as string));

    useEffect(() => {
        if (!isLoginModalOpen && ((!hasChecked && checkingStatus) || (hasChecked && !loggedIn)))
            dispatch(addModalAction('loginModal', '', null));
    }, [isLoginModalOpen, checkingStatus, hasChecked, loggedIn]);

    const context = filteredRoutes && filteredRoutes.length && filteredRoutes[0]?.hasContext && filteredRoutes[0]?.path !== '/codes';
    const codes = Boolean(window.location.pathname === '/explore');

    if (userLoaded) {
        return (
            <Layout className="animated fadeIn">
                <Container fluid>
                    <Suspense fallback={<Spinner large />}>
                        <Fragment>
                            {context && !codes && <Context />}
                            {context && codes && <CodesContext />}
                            <div
                                className={cx({ dimmed: dim, blurred: dim })}
                                style={{
                                    transition: 'all 0.2s ease'
                                }}
                            >
                              <Switch>
                                {routes.map((route) => {
                                    return (
                                        <Route
                                            component={route.component}
                                            exact={route.isExact || false}
                                            // i18nKey={route.i18nKey}
                                            key={route.key}
                                            path={route.path}
                                        />
                                    );
                                })}
                              </Switch>
                                {!routes.find((route) => pathname.includes(route.path)) && (
                                    <Redirect
                                        to={{
                                            pathname: `${routes[0].path}`
                                        }}
                                    />
                                )}
                            </div>
                        </Fragment>
                    </Suspense>
                </Container>
            </Layout>
        );
    } else {
        if (!loggedIn) {
            return (
                <Layout className="animated fadeIn">
                    <Container className="h-100 u-flex-center" fluid>
                        <Unauthorised />
                    </Container>
                </Layout>
            );
        }
        return (
            <Layout className="animated fadeIn">
                <Container className="h-100 u-flex-center" fluid>
                    <Spinner large />
                </Container>
            </Layout>
        );
    }
};

export default Dashboard;
