import { OverlayReportsRoutes, OverlayReportsActionTypes } from './overlays.actions';
import moment from 'moment';
import { apiOverlayDataSingleTag } from './api/singleTag.reducer';
import set from 'lodash.set';
import { apiOverlayData, CampaignPerformanceTableRow } from './api/campaignPerformance.reducers';
import { IAPIRoute } from 'Dashboard/views/SmarterCodes/redux/smartercodes.reducers';
import {
    apiOverlayDailyData,
    DailyBreakdownTableRow,
    DailyBreakdownFooterRow,
    DailyBreakdownChartData
} from './api/dailyBreakdown.reducer';
import { apiOverlayYearlyStats, YearlyStatsChartData } from './api/yearlyStats.reducer';
import { apiOverlayRealtimeData } from './api/realtimeData.reducer';

type Timestamp = {
    min: number;
    max: number;
};

type OverlaySingleTagOutput = {
    overlay_id: number;
    name: string;
    count: number;
};

type OverlayDataOutput = {
    commission_approved?: number;
    commission_declined?: number;
    commission_pending?: number;
    deleted?: number;
    engagement: number;
    overlay_on: number;
    overlay_id: number;
    overlay_loaded: number;
    overlay_name: string;
    overlay_shown: number;
    revenue_approved: number;
    revenue_declined: number;
    revenue_pending: number;
    sales: number;
    tag_name: string;
    overlay_type: string;
};

export type DailyData = {
    commission_approved?: number;
    commission_declined?: number;
    commission_pending?: number;
    engagement: number;
    overlay_loaded: number;
    overlay_shown: number;
    revenue_approved: number;
    revenue_declined: number;
    revenue_pending: number;
    sales: number;
    script_access: number;
    overlay_name: string;
};

export type DailyImpressions = {
    script_access: number;
};

type OverlayDailyDataOutput = { [key: string]: DailyData | DailyImpressions };

type OverlayYearlyStatsOutput = OverlayDailyDataOutput;

type BaseApiData = {
    timestamp: Timestamp;
    total: any[];
    map: any[];
};

export type OverlaySingleTagData = BaseApiData & {
    output: OverlaySingleTagOutput[];
};

export type OverlayData = BaseApiData & {
    output: {
        [tagId: number]: { [overlayId: number]: OverlayDataOutput };
    };
};

export type OverlayDailyData = BaseApiData & {
    output: {
        [date: string]: OverlayDailyDataOutput;
    };
};

export type OverlayRealtimeData = BaseApiData & {
    output: {
        funnel: Array<{
            Engagement: number;
            'Overlays Shown': number;
            'Repeat Users': number;
            'Unique Users': number;
        }>;
        realtime: Array<{
            engagement: number;
            first: string;
            last: string;
            overlay_loaded: number;
            overlay_shown: number;
            script_access: number;
        }>;
    };
};

export type OverlayYearlyStats = {
    timestamp: Timestamp;
    total: any[];
    map: any;
    output: {
        [date: string]: OverlayYearlyStatsOutput;
    };
};

export interface GraphNumberApiRoute {
    data: any;
    lastUpdated: string;
    requestBodyHash: string;
    isLoading: { [key: number]: boolean | undefined };
}

export interface OverlayReportsData {
    tableRows: CampaignPerformanceTableRow[];
    groupDeviceData: boolean;
    hidePaused: boolean;
    optionalColumns: Array<{ label: string; value: string }>;
}

export interface OverlayReportsDailyData {
    tableRows: DailyBreakdownTableRow[];
    footerRow: DailyBreakdownFooterRow;
    chartData: DailyBreakdownChartData;
}

export interface OverlayReportsDailyCampaignData {
    tableRows: DailyBreakdownTableRow[];
    footerRow: DailyBreakdownFooterRow;
}

export interface OverlaysState {
    [OverlayReportsRoutes.SINGLE_TAG]: any;
    [OverlayReportsRoutes.DATA]: OverlayReportsData;
    [OverlayReportsRoutes.DAILY_DATA]: OverlayReportsDailyData;
    [OverlayReportsRoutes.YEARLY_STATS]: { chartData: YearlyStatsChartData };
    [OverlayReportsRoutes.REALTIME_DATA]: any;
    api: {
        [OverlayReportsRoutes.SINGLE_TAG]: GraphNumberApiRoute;
        [OverlayReportsRoutes.DATA]: IAPIRoute<any>;
        [OverlayReportsRoutes.DAILY_DATA]: IAPIRoute<any>;
        [OverlayReportsRoutes.YEARLY_STATS]: IAPIRoute<any>;
        [OverlayReportsRoutes.REALTIME_DATA]: GraphNumberApiRoute;
    };
}

const initialState: OverlaysState = {
    singleTag: {},
    overlayData: {
        groupDeviceData: true,
        hidePaused: false,
        optionalColumns: [],
        tableRows: []
    },
    dailyData: {
        tableRows: [],
        footerRow: {} as DailyBreakdownFooterRow,
        chartData: {} as DailyBreakdownChartData
    },
    yearlyStats: {
        chartData: {} as YearlyStatsChartData
    },
    realtimeData: {},
    api: {
        singleTag: {} as GraphNumberApiRoute,
        overlayData: {} as IAPIRoute<any>,
        dailyData: {} as IAPIRoute<any>,
        yearlyStats: {} as IAPIRoute<any>,
        realtimeData: {} as GraphNumberApiRoute
    }
};

export function apiRequest(state, action) {
    let newState = { ...state };
    if (action.graphNumber) {
        newState = {
            ...newState,
            api: {
                ...newState.api,
                [action.route]: {
                    ...newState.api[action.route],
                    isLoading: {
                        ...newState.api[action.route].isLoading,
                        [action.graphNumber]: true
                    }
                }
            }
        };
    } else if (action.tableNumber) {
        newState = {
            ...newState,
            api: {
                ...newState.api,
                [action.route]: {
                    ...newState.api[action.route],
                    isLoading: {
                        ...newState.api[action.route].isLoading,
                        [action.tableNumber]: true
                    }
                }
            }
        };
    } else {
        newState = {
            ...newState,
            api: {
                ...newState.api,
                [action.route]: {
                    ...newState.api[action.route],
                    isLoading: true
                }
            }
        };
    }
    return newState;
}

export function apiData(state: OverlaysState, action) {
    let newState;

    const { data, context, extraParams } = action;

    switch (action.route) {
        case OverlayReportsRoutes.SINGLE_TAG:
            newState = apiOverlayDataSingleTag(state, data, context, extraParams);
            newState.api[action.route] = {
                ...newState.api[action.route],
                isLoading: {
                    ...newState.api[action.route].isLoading,
                    [extraParams.graph_number]: false
                },
                data: {
                    ...newState.api[action.route].data,
                    [extraParams.graph_number]: action.data
                },
                requestBodyHash: {
                    ...newState.api[action.route].requestBodyHash,
                    [extraParams.graph_number]: action.requestBodyHash
                },
                lastUpdated: {
                    ...newState.api[action.route].lastUpdated,
                    [extraParams.graph_number]: moment().toISOString()
                }
            };
            break;
        case OverlayReportsRoutes.DATA:
            newState = apiOverlayData(state, data);
            newState.api[action.route] = {
                isLoading: false,
                data: action.data,
                lastUpdated: moment().toISOString(),
                requestBodyHash: action.requestBodyHash
            };
            break;
        case OverlayReportsRoutes.DAILY_DATA:
            newState = apiOverlayDailyData(state, data);
            newState.api[action.route] = {
                isLoading: false,
                data: action.data,
                lastUpdated: moment().toISOString(),
                requestBodyHash: action.requestBodyHash
            };
            break;
        case OverlayReportsRoutes.YEARLY_STATS:
            newState = apiOverlayYearlyStats(state, data);
            newState.api[action.route] = {
                isLoading: false,
                data: action.data,
                lastUpdated: moment().toISOString(),
                requestBodyHash: action.requestBodyHash
            };
            break;
        case OverlayReportsRoutes.REALTIME_DATA:
            newState = apiOverlayRealtimeData(state, data, context, extraParams);
            newState.api[action.route] = {
                ...newState.api[action.route],
                isLoading: {
                    ...newState.api[action.route].isLoading,
                    [extraParams.pixel_table_number]: false
                },
                data: {
                    ...newState.api[action.route].data,
                    [extraParams.pixel_table_number]: action.data
                },
                requestBodyHash: {
                    ...newState.api[action.route].requestBodyHash,
                    [extraParams.pixel_table_number]: action.requestBodyHash
                },
                lastUpdated: {
                    ...newState.api[action.route].lastUpdated,
                    [extraParams.pixel_table_number]: moment().toISOString()
                }
            };
            break;
        default:
            newState = { ...state };
            newState.api[action.route] = {
                data: action.data,
                lastUpdated: moment().toISOString(),
                requestBodyHash: action.requestBodyHash
            };
            break;
    }

    return newState;
}

export function overlays(state = initialState, action) {
    switch (action.type) {
        case OverlayReportsActionTypes.API_REQUEST:
            return apiRequest(state, action);
        case OverlayReportsActionTypes.API_DATA:
            return apiData(state, action);
        case OverlayReportsActionTypes.GROUP_DEVICE_DATA:
            const groupDeviceState = {
                ...state,
                overlayData: { ...state.overlayData, groupDeviceData: action.value }
            };
            if (state.api.overlayData.data) {
                return apiOverlayData(groupDeviceState, state.api.overlayData.data);
            } else {
                return groupDeviceState;
            }
        case OverlayReportsActionTypes.HIDE_PAUSED_CAMPAIGNS:
            const hidePausedState = {
                ...state,
                overlayData: { ...state.overlayData, hidePaused: action.value }
            };
            if (state.api.overlayData.data) {
                return apiOverlayData(hidePausedState, state.api.overlayData.data);
            } else {
                return hidePausedState;
            }
        case OverlayReportsActionTypes.OPTIONAL_COLUMNS:
            const optionalColumnsState = {
                ...state,
                overlayData: { ...state.overlayData, optionalColumns: action.value }
            };
            if (state.api.overlayData.data) {
                return apiOverlayData(optionalColumnsState, state.api.overlayData.data);
            } else {
                return optionalColumnsState;
            }
        default:
            return { ...state };
    }
}
