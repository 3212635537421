import React from 'react';
import { Card, CardBody } from 'reactstrap';
import CodesGeographicalBreakdown from '../../components/CodesGeographicalBreakdown/CodesGeographicalBreakdown';
import CodesMap from '../../components/CodesMap/CodesMap';

const GlobalAttempts = (): JSX.Element => {
    return (
        <Card className="card-table">
            <div className="card-media">
                <CodesMap />
            </div>

            <CardBody>
                <CodesGeographicalBreakdown />
            </CardBody>
        </Card>
    );
};

export default GlobalAttempts;
