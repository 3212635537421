import { IUserAuthChangePassword, API } from 'services/api.service';
import { hideModalAction, getUserDataAction } from 'redux/actions';

export enum AccountActionTypes {
    CHANGE_PASSWORD_RESET = 'ACCOUNT_CHANGE_PASSWORD_RESET',
    CHANGE_PASSWORD_REQUEST = 'ACCOUNT_CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS = 'ACCOUNT_CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE = 'ACCOUNT_CHANGE_PASSWORD_FAILURE',
    UPDATE_MFA_INIT_REQUEST = 'ACCOUNT_UPDATE_MFA_INIT_REQUEST',
    UPDATE_MFA_INIT_SUCCESS = 'ACCOUNT_UPDATE_MFA_INIT_SUCCESS',
    UPDATE_MFA_INIT_FAILURE = 'ACCOUNT_UPDATE_MFA_INIT_FAILURE',
    UPDATE_MFA_CONF_REQUEST = 'ACCOUNT_UPDATE_MFA_CONF_REQUEST',
    UPDATE_MFA_CONF_SUCCESS = 'ACCOUNT_UPDATE_MFA_CONF_SUCCESS',
    UPDATE_MFA_CONF_FAILURE = 'ACCOUNT_UPDATE_MFA_CONF_FAILURE',
    REMOVE_MFA_REQUEST = 'ACCOUNT_REMOVE_MFA_REQUEST',
    REMOVE_MFA_SUCCESS = 'ACCOUNT_REMOVE_MFA_SUCCESS',
    REMOVE_MFA_FAILURE = 'ACCOUNT_REMOVE_MFA_FAILURE',
    CHANGE_MFA_RESET = 'ACCOUNT_CHANGE_MFA_RESET',
    CHANGE_MFA_ERROR_RESET = 'ACCOUNT_CHANGE_MFA_ERROR_RESET',
    UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST',
    UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS',
    UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE'
}

export type UploadImage = {
    isUploading: boolean;
    error?: string | null;
    imageLink?: string;
    imageType: 'banner' | 'profile_image';
};

/**
 * @description Remove things like errors, etc
 */
export const changePasswordInitialiseAction = () => ({
    type: AccountActionTypes.CHANGE_PASSWORD_RESET
});

/**
 *
 * @param params Object of new, current, confirm passwords
 */
export const changePasswordAction = (params: IUserAuthChangePassword) => async dispatch => {
    dispatch({
        type: AccountActionTypes.CHANGE_PASSWORD_REQUEST
    });
    try {
        let resp = await API.user()
            .auth()
            .changePassword(params);

        if (resp.result === 'error') {
            throw new Error(resp.errors && resp.errors.length ? resp.errors[0] : 'Unknown error');
        }

        dispatch({
            type: AccountActionTypes.CHANGE_PASSWORD_SUCCESS
        });
    } catch (error) {
        dispatch({
            type: AccountActionTypes.CHANGE_PASSWORD_FAILURE,
            // @ts-ignore
            error: error.errorMessage
        });
    }
    setTimeout(() => {
        dispatch(changePasswordInitialiseAction());
    }, 3000);
};

export const updateMFAInitiateAction = password => async dispatch => {
    dispatch({
        type: AccountActionTypes.UPDATE_MFA_INIT_REQUEST
    });
    try {
        let resp = await API.user()
            .auth()
            .setupMfa()
            .post(password);

        dispatch({
            type: AccountActionTypes.UPDATE_MFA_INIT_SUCCESS,
            qr_code: resp.qr_code
        });
    } catch (error) {
        dispatch({
            type: AccountActionTypes.UPDATE_MFA_INIT_FAILURE,
            // @ts-ignore
            error: error.errorMessage
        });
        setTimeout(() => {
            dispatch(changeMFAResetErrorAction());
        }, 5000);
    }
};

export const updateMFAConfirmAction = mfa => async dispatch => {
    dispatch({
        type: AccountActionTypes.UPDATE_MFA_CONF_REQUEST
    });
    try {
        let resp = await API.user()
            .auth()
            .setupMfa()
            .patch(JSON.stringify({ mfa }));

        if (resp.result === 'error') {
            throw new Error(resp.errors && resp.errors.length ? resp.errors[0] : 'Unknown error');
        }

        dispatch({
            type: AccountActionTypes.UPDATE_MFA_CONF_SUCCESS
        });

        setTimeout(() => {
            dispatch(hideModalAction());
            setTimeout(() => {
                dispatch(changeMFAResetAction());
            }, 300);
        }, 1500);
    } catch (error) {
        dispatch({
            type: AccountActionTypes.UPDATE_MFA_CONF_FAILURE,
            // @ts-ignore
            error: error.errorMessage
        });
        setTimeout(() => {
            dispatch(changeMFAResetErrorAction());
        }, 5000);
    }
};

export const deleteMFAAction = (password: string) => async dispatch => {
    dispatch({
        type: AccountActionTypes.REMOVE_MFA_REQUEST
    });
    try {
        await API.user()
            .auth()
            .mfa()
            .delete(password);

        dispatch({
            type: AccountActionTypes.REMOVE_MFA_SUCCESS
        });
        setTimeout(() => {
            dispatch(hideModalAction());
            setTimeout(() => {
                dispatch(changeMFAResetAction());
            }, 300);
        }, 2000);
    } catch (error) {
        dispatch({
            type: AccountActionTypes.REMOVE_MFA_FAILURE,
            // @ts-ignore
            error: error.errorMessage
        });
        setTimeout(() => {
            dispatch(changeMFAResetErrorAction());
        }, 5000);
    }
};
export const changeMFAResetAction = () => ({
    type: AccountActionTypes.CHANGE_MFA_RESET
});
export const changeMFAResetErrorAction = () => ({
    type: AccountActionTypes.CHANGE_MFA_ERROR_RESET
});

export const uploadImageAction = (image, imageType) => async dispatch => {
    const formData = new FormData();

    if (image) {
        formData.append(imageType, image);
    }
    let response: any = null;
    let error: string | null = null;

    dispatch({ type: AccountActionTypes.UPLOAD_IMAGE_REQUEST, payload: { imageType } });

    try {
        response = await API.user()
            .image()
            .post(formData);

        const imageLink = response['1'][imageType];

        setTimeout(() => {
            dispatch({
                type: AccountActionTypes.UPLOAD_IMAGE_SUCCESS,
                payload: {
                    imageType,
                    imageLink
                }
            });
        }, 300);
    } catch (e) {
        // @ts-ignore
        if (e.errorMessage) {
            // @ts-ignore
            error = e.errorMessage;
        } else {
            error = 'Something went wrong. Please try again';
        }
        setTimeout(() => {
            dispatch({
                type: AccountActionTypes.UPLOAD_IMAGE_FAILURE,
                payload: { imageType, error }
            });
        }, 300);
    }
};
