import React from 'react';
import ReactDOM from 'react-dom';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';

import * as serviceWorker from './serviceWorker';

import App from './App';
import './scss/index.scss';

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

ReactGA.initialize('G-SPG9NEKC9E');
ReactGA.pageview(window.location.pathname + window.location.search);

if (localStorage.getItem('theme') === 'dark') {
    document.documentElement.setAttribute('data-theme', 'dark');
}

document.addEventListener('mouseover', (e) => {
    const element = e.target;
    const parentElement = element ? element['parentNode'] : null;
    const elementName = element ? element['nodeName'] : null;
    const parentName = parentElement ? parentElement['nodeName'] : null;
    if (parentName !== '#document') {
        const elementClass = element ? element['classList'] : null;
        const parentClass = parentElement ? parentElement['classList'] : null;
        if (
            elementName === 'A' ||
            parentName === 'A' ||
            elementName === 'BUTTON' ||
            parentName === 'BUTTON'
        ) {
            setTimer(e.target, 'Button Hovered');
        } else if (elementClass && elementClass.value.indexOf('sort-column') > -1) {
            setTimer(e.target, 'Table Sort Button Hovered');
        } else if (parentClass && parentClass.value.indexOf('sort-column') > -1) {
            setTimer(e.target, 'Table Sort Button Hovered');
        } else if (
            (elementClass && elementClass.value.indexOf('date-select') > -1) ||
            elementClass.value.indexOf('DateInput') > -1 ||
            elementClass.value.indexOf('DateRange') > -1
        ) {
            setTimer(e.target, 'Date Selector Button Hovered');
        } else if (
            (parentClass && parentClass.value.indexOf('date-select') > -1) ||
            parentClass.value.indexOf('DateInput') > -1 ||
            parentClass.value.indexOf('DateRange') > -1
        ) {
            setTimer(e.target, 'Date Selector Button Hovered');
        }
    }
});

const setTimer = (element, eventMessage) => {
    const timeout = setTimeout(() => {
        ReactGA.event({
            category: 'Hover',
            action: 'View Item' + ' - ' + eventMessage
        });
    }, 2000);

    const handleTimeout = () => {
        clearTimeout(timeout);
        element.removeEventListener('mouseout', handleTimeout, true);
    };

    element.addEventListener('mouseout', handleTimeout, true);
};

document.addEventListener('click', (e) => {
    const element = e.target;
    const elementName = element ? element['nodeName'] : null;
    const elementValue = element ? element['innerText'] : null;
    const elementClass = element ? element['classList']['value'] : null;
    if (elementName !== 'MAIN') {
        ReactGA.event({
            category: 'Click',
            action:
                (elementName?.toLowerCase() || '') +
                ' - ' +
                ((elementValue?.length || 0) < 30 ? elementValue : elementClass)
        });
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
