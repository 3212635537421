import {geoMercator, geoPath} from 'd3-geo';
import {FeatureCollection, GeoJsonProperties, Geometry} from 'geojson';
import React, {useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import {feature} from 'topojson-client';
import {Objects, Topology} from 'topojson-specification';
import {ApiCountry} from '../CodesMap/CodesMap';
import {useJsonFetch} from './useJsonFetch';
import './WorldMap.scss';

type Props = {
    apiData: Array<ApiCountry>;
    totalAttempts: number;
};

type Country = {
    id: number;
    name: string;
};

export const legendColors = {
    low: ['var(--colour-map--1)', 'var(--colour-map--2)'],
    medium: ['var(--colour-map--3)', 'var(--colour-map--4)'],
    high: ['var(--colour-map--5)', 'var(--colour-map--6)'],
    default: 'var(--colour-map--default)'
};

const getFillColor = (attempts = 0, totalAttempts = 0) => {
    const percentage = (attempts / totalAttempts) * 100;
    switch (true) {
        case percentage > 0 && percentage < 10:
            return legendColors.low[0];
        case percentage >= 10 && percentage < 20:
            return legendColors.low[1];
        case percentage >= 20 && percentage < 30:
            return legendColors.medium[0];
        case percentage >= 30 && percentage < 40:
            return legendColors.medium[1];
        case percentage >= 40 && percentage < 50:
            return legendColors.high[0];
        case percentage >= 50:
            return legendColors.high[1];
        default:
            return legendColors.default;
    }
};

const projection = () => {
    return geoMercator()
        .scale(100)
        .translate([800 / 2, 450 / 2]);
};

const WorldMap: React.FC<Props> = ({ apiData, totalAttempts }) => {
    const rawWorldData = useJsonFetch<Topology<Objects<GeoJsonProperties>>>('/world-110m.json');
    const countryNames = useJsonFetch<Country[]>('/world-110m-country-names.json') ?? {};
    const features = (rawWorldData ?
      (feature(
        rawWorldData,
        rawWorldData.objects.countries
      ) as FeatureCollection<Geometry, GeoJsonProperties>).features : []);
    const worldData = features.map(
          ({ type, id, properties, geometry })=> {
        const { code, name } = countryNames && id && countryNames[id] ?
          countryNames[id] : { code: "UC", name: "Unknown Country" };
        const attempts = apiData.reduce(
            (acc, { countryCode, attempts = 0 }) => code && code === countryCode ? acc + attempts : acc,
            0
        );
        return { type, id, properties, geometry, code, name, attempts };
    }).filter(({ id }) => id !== '-99');

    // Keep tooltip updated with the correct values
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [worldData, countryNames]);

    return (
        <div className="map-container">
            <div className="map-scale">
                <svg data-testid="world-map" viewBox="80 -70 635 415" width="100%">
                    <g className="countries">
                        {worldData.map(({ id, name, attempts, type, properties, geometry }) => {
                                const tooltipMessage = `Code Attempts <br /> ${name ? `${name} <br />` : ''}  ${attempts}`;
                                const colour = attempts
                                    ? getFillColor(attempts, totalAttempts)
                                    : getFillColor();
                                const d = geoPath().projection(projection())({ id, type, properties, geometry }) ??
                                    undefined;

                                return (
                                    <path
                                        className="country"
                                        d={d}
                                        data-for={name}
                                        data-testid={name}
                                        data-tip={tooltipMessage}
                                        fill={colour}
                                        id={`${id}`}
                                        key={`path-${id}`}
                                        stroke={colour}
                                        strokeWidth={1}
                                    />
                                );
                            })}
                    </g>
                </svg>

                {worldData.filter(({ attempts }) => attempts > 0).map(({ name }) => {
                        return (
                            <ReactTooltip
                                id={name}
                                key={`tooltip-${name}`}
                                multiline
                            />
                        );
                })}
            </div>
        </div>
    );
};

export default WorldMap;
