import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
// Functions
import pageButtons from './helpers';
// Styles
import './PaginationButtons.scss';

interface Props {
    pageSize: number;
    totalRows: number;
    pageIndex: number;
    goToPage: (pageIndex: number) => void;
}

const PaginationButtons = ({ pageSize, totalRows, pageIndex, goToPage }: Props): JSX.Element => {
    const maxPage = Math.ceil(totalRows / pageSize);
    return (
        <div className="pagination-buttons">
            <ButtonGroup>
                {/* == Back == */}
                {pageIndex !== 0 && (
                    <Button
                        outline
                        size="sm"
                        color="secondary"
                        disabled={pageIndex <= 0}
                        onClick={() => {
                            goToPage(pageIndex - 1);
                        }}
                        className="page-back"
                    >
                        {'<'}
                    </Button>
                )}

                {/* == Numbered == */}
                {pageButtons(pageIndex, goToPage, maxPage, 5)}

                {/* == Forward == */}
                {pageIndex + 1 !== maxPage && (
                    <Button
                        outline
                        size="sm"
                        color="secondary"
                        disabled={pageIndex >= maxPage - 1}
                        onClick={() => {
                            goToPage(pageIndex + 1);
                        }}
                        className="page-forward"
                    >
                        {'>'}
                    </Button>
                )}
            </ButtonGroup>
        </div>
    );
};

export default PaginationButtons;
