import DownloadWrapper from 'components/Charts/DownloadWrapper';
import PieChart from 'components/Charts/PieChart/PieChart';
import SpinnerCard from 'components/Spinner/SpinnerCard';
import React, { createRef, FC, useState } from 'react';
import { ChartProps } from 'react-chartjs-2';
import { Col, Row } from 'reactstrap';
import { getDatasets } from './helpers';

type Props = {
    types: any;
    pieDatasets;
    isLoading?: boolean;
};

const ValidityGraphicalSummary: FC<Props> = ({ isLoading, types, pieDatasets }: Props) => {
    const [pieRef, setPieRef] = useState(createRef());

    const headerText = 'Code Category Breakdown';
    const labels = [
        'Invalid',
        'No Discount',
        'Full Discount',
        'Basket Discount',
        'Delivery Discount'
    ];
    const options = { legend: { display: true, position: 'left' } };
    const datasets = getDatasets(pieDatasets);

    // console.log('pieDatasets: ', pieDatasets);

    const error = (datasets?.[0]?.data || []).reduce((prev, next) => (prev || 0) + (next || 0), 0)
        ? ''
        : "You haven't had any code attempts yet";

    if (isLoading) return <SpinnerCard />;

    return (
        <DownloadWrapper canvasRef={pieRef} downloadName={headerText}>
            <PieChart
                datasets={datasets}
                error={error}
                labels={Object.keys(types).map((key) => types[key].text)}
                options={options}
                setRef={setPieRef}
            />
        </DownloadWrapper>
    );
};

export default ValidityGraphicalSummary;
