import React from 'react';
import './Download.scss';
import { CSVLink } from 'react-csv';
import { processDataToDownload } from './helper';

type Props = {
    fileName;
    table;
    footerEnabled;
};
const Download = ({ fileName, table, footerEnabled }: Props): JSX.Element => {
    const tableHeaders = table.getHeaderGroups();
    const tableRawData = table.getSortedRowModel().rows;
    const tableFooters = table.getFooterGroups();
    const dataToDownload = processDataToDownload(tableHeaders, tableRawData, footerEnabled ? tableFooters : [], table);
    return (
        <div className="download-navigation">
            <CSVLink
                data={dataToDownload.csvData}
                filename={fileName}
                headers={dataToDownload.csvHeaders}
                target="_blank"
            >
                <span className="material-icons-outlined">download</span>
            </CSVLink>
        </div>
    );
};
export default Download;
