import * as FileSaver from 'file-saver';
import React, { FC, Fragment, ReactChild } from 'react';
import { Col, Row } from 'reactstrap';
import DownloadDropdown from 'components/DownloadDropdown/DownloadDropdown';

/**
 * coverts a base64 string into an image Blob
 */
function b64toBlob(base64Data: string, contentType: string, sliceSize: number = 512): Blob {
    var byteCharacters = atob(base64Data);
    var byteArrays: any[] = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

/**
 * triggers a browser file download from a base64 string
 */
function downloadBase64AsImg(base64Data: string, contentType: string, name: string): void {
    try {
        const ext = contentType.split('/')[1];
        const prepend = `data:${contentType};base64`;
        const formattedBase64Data = base64Data.replace(`${prepend},`, '');
        const blob = b64toBlob(formattedBase64Data, contentType);
        const file = new File([blob], `${name}.${ext}`, { type: prepend });
        FileSaver.saveAs(file);
    } catch (err) {
        console.error('Could not generate file download.'); // eslint-disable-line no-console
    }
}

/**
 * opens a base64 image string in a new browser window and
 * optionally opens a print dialog window in the browser
 */
function openBase64AsPrintDialog(
    base64Data: string,
    contentType: string,
    openPrintDialog: boolean = true
): void {
    try {
        const prepend = `data:${contentType};base64`;
        const formattedBase64Data = base64Data.replace(`${prepend},`, '');
        const blob = b64toBlob(formattedBase64Data, contentType);
        const file = new Blob([blob], { type: contentType });
        const fileURL = URL.createObjectURL(file);
        const downloadWindow = window.open(fileURL);
        if (downloadWindow && openPrintDialog === true) {
            downloadWindow.focus();
            downloadWindow.print();
        }
    } catch (err) {
        console.error('Could not open file.'); // eslint-disable-line no-console
    }
}

/**
 * reformats a HTML canvas element by applying a white background
 * and padding around on all sides
 */
function getCleanedBase64FromCanvas(sourceCanvas: HTMLCanvasElement, contentType: string): string {
    try {
        const padding = 25;
        const cleanCanvas = document.createElement('canvas');
        const ctx = cleanCanvas.getContext('2d');
        cleanCanvas.width = sourceCanvas.width + padding * 2;
        cleanCanvas.height = sourceCanvas.height + padding * 2;
        if (ctx) {
            ctx.beginPath();
            ctx.rect(0, 0, cleanCanvas.width, cleanCanvas.height);
            ctx.fillStyle = 'white';
            ctx.fill();
            ctx.drawImage(sourceCanvas, padding, padding);
            return cleanCanvas.toDataURL(contentType);
        }
    } catch (err) {
        console.error('Could not open file.'); // eslint-disable-line no-console
    }
    return '';
}

type Props = {
    // required
    canvasRef: any;
    children: ReactChild | string;
    downloadName: string;
    // optional
    header?: string;
    subHeader?: string;
};

const DownloadWrapper: FC<Props> = (props: Props) => {
    const pngContentType: string = 'image/png';

    return <Fragment>{props.children}</Fragment>; // TODO: chart download currently disabled until further notice from Joe

    function getCanvasAsBase64(contentType: string): string {
        if (props.canvasRef) {
            return getCleanedBase64FromCanvas(props.canvasRef.canvas, contentType);
        }
        return '';
    }

    return (
        <Fragment>
            <Row className="mb-2">
                <Col sm="5">
                    {props.header && <div className="mb-0">{props.header}</div>}
                    {props.subHeader && <div className="small text-muted">{props.subHeader}</div>}
                </Col>
                <Col className="d-none d-sm-inline-block" sm="7">
                    <DownloadDropdown
                        className="float-right mr-2"
                        dropdown={[
                            {
                                key: 1,
                                text: 'PNG',
                                onClick: () =>
                                    downloadBase64AsImg(
                                        getCanvasAsBase64(pngContentType),
                                        pngContentType,
                                        props.downloadName
                                    )
                            }
                        ]}
                        onClickPrint={() =>
                            openBase64AsPrintDialog(
                                getCanvasAsBase64(pngContentType),
                                pngContentType
                            )
                        }
                    />
                </Col>
            </Row>
            {props.children}
        </Fragment>
    );
};

export default DownloadWrapper;
