import { IDashboardState } from 'Dashboard/redux/dashboard.reducer';
import moment from 'moment-timezone';
import { GeneralFailureAction } from 'redux/actions';
import { API } from 'services/api.service';
import { IAPIRoute } from './smartercodes.reducers';
import { DashboardActionTypes } from 'Dashboard/redux/dashboard.actions';
import {
  CodesViewActionTypes,
  IGetReportRouteAction,
  ReferralsViewActionTypes,
  ReportsRoutes,
  SmarterCodesActionTypes
} from './types';
import { getPath } from './utils';

export const GetReportRouteAction =
  ({
    refresh: isForceRefresh = false,
    route,
    extraParams = {},
    readStore,
    cacheMinutes = 60
  }: IGetReportRouteAction) =>
  async (dispatch, getState) => {
    const state = getState();
    const { timezone, currency } = state.user.settings;
    const { context }: IDashboardState = state.dashboard;
    const {
        requestBodyHash,
        lastUpdated,
        isLoading
    }: IAPIRoute<any> = state.smartercodes.api[route];
    const { startDate, endDate, tag, activeDKs, channels, sources, publishers } = context;
    const format = 'YYYY-MM-DD';

    if (!tag.tag_index) {
      const failObj = { refresh: isForceRefresh, route, extraParams };
      const msg = 'A tag should have been specified and it was not.';
      dispatch(GeneralFailureAction(failObj, msg));
      return;
    }

    const isCodesList = route === ReportsRoutes.FILTER_CODES;

    const body = {
      ...extraParams,
      start_date: moment(startDate).format(format),
      end_date: moment(endDate).format(format),
      tag_index: [tag.tag_index],
      timezone,
      dynamic_key: JSON.stringify(activeDKs),
      currency,
      ...(isCodesList ? { channel_id: channels.map(({ id }) => id) } : {}),
      ...(isCodesList ? { source_id: sources.map(({ id }) => id) } : {}),
      ...(isCodesList ? { publisher_id: publishers.map(({ id }) => id) } : {}),
    };
    const newRequestBodyHash = JSON.stringify(body);
    const isCached = requestBodyHash === newRequestBodyHash;
    const isUseCache = isCached && !isForceRefresh;

    const lastMoment = moment(lastUpdated);
    const now = moment();
    const diff = now.diff(lastMoment, 'minutes');
    const isOutdated = diff > cacheMinutes;

    if (isLoading || (isUseCache && !isOutdated)) return;

    // Prepare UI for new data
    dispatch({ type: SmarterCodesActionTypes.API_REQUEST, route });

    // Make request
    const { data } = await API.reports().smartercodes({
      path: getPath(route),
      body: newRequestBodyHash
    });


    // If we're getting advanced view data, populate context
    if (route === ReportsRoutes.CODE_REVIEW) {
      dispatch({
        type: DashboardActionTypes.CODE_REVIEW_DATA,
        data,
        route,
        context,
        requestBodyHash: newRequestBodyHash,
        extraParams
      });
    }

    dispatch({
      type: SmarterCodesActionTypes.API_DATA,
      data,
      route,
      context,
      requestBodyHash: newRequestBodyHash,
      extraParams,
      readStore
    });

    dispatch({
      type: SmarterCodesActionTypes.API_SUCCESS,
      route
    });
  };

export const setCurrentCodeAction = (identity) => {
    return {
        type: CodesViewActionTypes.SET,
        payload: { identity }
    };
};

export const setReferralsBreakdownAction = (
  value: { type: string, name: string, id: number } | null
) => ({
    type: ReferralsViewActionTypes.SET_BREAKDOWN,
    payload: value
});
