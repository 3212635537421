import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector as uSel } from 'react-redux';
import { IAppStoreState as S } from 'redux/interfaces';
import {
  UpdateEndedAction,
  UpdateInitiatedAction
} from '../../../../redux/dashboard.actions';
import { GetReportRouteAction } from '../../redux/smartercodes.actions';
import { ReportsRoutes } from '../../redux/types';
import CodesDomainReferrals from './components/CodesDomainReferrals/CodesDomainReferrals';
import CodesSources from './components/CodesSources/CodesSources';
import CodesSummary from './components/CodesSummary/CodesSummary';
import Daily from './components/Daily/Daily';
import GlobalAttempts from './components/GlobalAttempts/GlobalAttempts';
import CodesMessages from './components/CodesMessages/CodesMessages';
import useWake from '../../../../../utils/customHooks/useWake';
import moment from 'moment';
import CodesBaskets from './components/CodesBaskets/CodesBaskets';

const Codes = (): JSX.Element => {
  const dispatch = useDispatch();
  const context = uSel((state: S) => state.dashboard?.context);
  const isCodeReviewLoading = uSel((state: S) => state.smartercodes?.api?.codeReview?.isLoading);
  const isCodeDetailsLoading = uSel((state: S) => state.smartercodes?.api?.codeDetails?.isLoading);
  //Passing store to get timezone in code details reducer
  const store = uSel((state: S) => state);
  const { channels, sources, publishers, codes } = context;

  // Reset scroll on page load
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const requestCodeList = () => {
    dispatch(GetReportRouteAction({ route: ReportsRoutes.CODE_REVIEW }));
  };

  const requestCodeReport = () => {
      dispatch(
        GetReportRouteAction({
          route: ReportsRoutes.CODE_DETAILS,
          extraParams: {
            code_id: codes.map((code)=>code.id),
            channel_id: channels.map((channel)=>channel.id),
            publisher_id: publishers.map((publisher)=>publisher.id),
            source_id: sources.map((source)=>source.id),
          },
          readStore: store
        })
      );
  };

  useWake(() => {
    const lastDay = moment(context.endDate, 'YYYY/MM/DD');
    const today = moment();
    const includesToday = lastDay.isSame(today, "day");
    if(includesToday) {
      requestCodeList();
      requestCodeReport();
    }
  }, 70);

  useEffect(() => {
    requestCodeList();
    requestCodeReport();
  }, [JSON.stringify(context)]);

  // Fade out / in content on load
  useEffect(() => {
    // Out
    if (
      (isCodeDetailsLoading && !isCodeReviewLoading) ||
      (isCodeReviewLoading && !isCodeDetailsLoading)
    )
      dispatch(UpdateInitiatedAction());

    // In
    if (!isCodeReviewLoading && !isCodeDetailsLoading) {
      const timeoutId = setTimeout(() => dispatch(UpdateEndedAction()), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isCodeReviewLoading, isCodeDetailsLoading]);

  return (
    <Fragment>
      <Helmet>
        <title>intent.ly - Explore</title>
      </Helmet>
      <CodesSummary />
      <Daily />
      <CodesMessages />
      <CodesBaskets />
      <CodesSources />
      <CodesDomainReferrals />
      <GlobalAttempts />
    </Fragment>
  );
};

export default Codes;
