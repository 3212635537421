import React, { FC, useState } from 'react';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import { standardStyles, standardTheme } from '../../utils/reactSelectStyles';
import './Select.scss';

type Props = {
    defaultValue?: { value: string | number; label: string | number };
    options: Array<{
        label: string | number | Object;
        value: string | number | Object;
        color?: string;
        isFixed?: boolean;
        isDisabled?: boolean;
    }>;
    onChange: Function;
    onMenuOpen?: Function;
    onMenuClose?: Function;
    isSearchable?: boolean;
    isClearable?: boolean;
    isMulti?: boolean;
    label?: string;
    className?: string;
    classNamePrefix?: string;
    placeholder?: string;
    containerClassName?: string;
    value?: { value; label } | { value; label; }[];
    name?;
    styles?;
    disabled?: boolean;
    style?:Record<string, unknown>;
};

const Select: FC<Props> = (props: Props) => {
    const { value, options, className } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const animatedComponents = props.isMulti ? makeAnimated() : undefined;
    const label = props.label ? <span className="mr-4">{props.label}</span> : '';

    return (
        <div
            className={`select-container ${
                typeof props.containerClassName === 'string' ? props.containerClassName : 'mb-3'
            }${
              props.disabled ? ' disabled' : ''
            }`}
        >
            {label}
            <ReactSelect
                className={`select-input${className ? ` ${className}` : ''}`}
                classNamePrefix={props.classNamePrefix}
                closeMenuOnSelect={!props.isMulti}
                components={animatedComponents}
                defaultValue={props.defaultValue}
                isClearable={props.isSearchable}
                isDisabled={props.disabled}
                isMulti={props.isMulti}
                isSearchable={props.isClearable}
                menuIsOpen={dropdownOpen}
                name={props.name}
                onChange={(input) => {
                    props.onChange(input);
                    if (!props.isMulti) {
                        setDropdownOpen(!dropdownOpen);
                    }
                }}
                onMenuClose={() => {
                    setDropdownOpen(false);
                    props.onMenuClose && props.onMenuClose();
                }}
                onMenuOpen={() => {
                    setDropdownOpen(true);
                    props.onMenuOpen && props.onMenuOpen();
                }}
                options={options}
                placeholder={props.placeholder}
                styles={{...standardStyles, ...props.style}}
                theme={standardTheme}
                value={value}
            />
        </div>
    );
};

export default Select;
