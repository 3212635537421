import React from 'react';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import './BackButton.scss';
import ButtonRipple from 'components/Buttons/ButtonRipple/ButtonRipple';

interface Props {
    onClick: () => void;
    floating?: boolean;
}

const UpdatePassword = ({ onClick, floating = false }: Props): JSX.Element => {
    return (
        <ButtonRipple
            className={classNames('back-button', floating ? 'floating' : '')}
            onClick={onClick}
            type="button"
        >
            <span className="material-icons-outlined">arrow_back</span>
        </ButtonRipple>
    );
};

export default UpdatePassword;
