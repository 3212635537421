import React, { FunctionComponent } from 'react';
import { Button, Col, Row } from 'reactstrap';
import CustomInput from '../../../../../Form/Input/Input';

type Props = {
    email: string;
    onChange: (value: string) => void;
    onSubmit: () => void;
    disableSubmitButton: boolean;
};
const ForgotEmail: FunctionComponent<Props> = ({
    email,
    onChange,
    onSubmit,
    disableSubmitButton
}: Props) => {
    return (
        <>
            <CustomInput
                autoComplete="email"
                className="mb-4"
                id="email-password-reset"
                label="Email"
                onChange={(e) => {
                    const value = e.target.value;
                    onChange(value);
                }}
                type="text"
                value={email}
            />

            <Row>
                <Col>
                    <Button
                        className="btn-block"
                        color="primary"
                        onClick={() => {
                            onSubmit();
                        }}
                        type="button"
                        disabled={disableSubmitButton}
                    >
                        Send email
                    </Button>
                </Col>
            </Row>
        </>
    );
};
export default ForgotEmail;
