import React, { useMemo, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from '@tanstack/react-table';
import classNames from 'classnames';
import ButtonRipple from 'components/Buttons/ButtonRipple/ButtonRipple';
import MenuTrigger from 'components/MenuTrigger/MenuTrigger';
import { is } from 'ramda';
import Download from './components/Download/Download';
import PaginationButtons from './components/Pagination/PaginationButtons/PaginationButtons';
import Search from './components/Search/Search';
import { fuzzyFilter, paginationLabel } from './helpers';
import './ReactTable.scss';
import { ColumnDef } from '@tanstack/table-core';

type Props = {
  data: Record<string, string | number>[];
  columns: any,
  // columns: (ColumnDef<Record<string, string | number | unknown>, string> | ColumnDef<Record<string, string | number | unknown>, number>)[];
  rowsMeta?: { className?: string; onClick?: (row) => void };
  search?: boolean;
  pagination?: boolean | number[];
  footer?: boolean;
  download?: boolean | string;
  sort?: { id: string; desc: boolean };
};

const ReactTable = ({
    data,
    columns,
    rowsMeta,
    search,
    pagination,
    footer,
    download,
    sort = { id: columns?.[0]?.id ?? '', desc: false }
}: Props): JSX.Element => {
    const pageSizes = (() => {
        if (is(Array, pagination)) return pagination as number[];
        if (pagination === true) return [10, 30, 50, 100000000000000];
        return false;
    })();
    const dataString = JSON.stringify(data);
    const columnString = JSON.stringify(columns);

    const [sorting, setSorting] = useState<SortingState>([{ id: sort.id, desc: sort.desc }]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [rowSelection, setRowSelection] = useState({});

    const dataMemo = useMemo(() => data, [dataString]);
    const columnsMemo = useMemo(() => columns, [columnString]);

    const table = useReactTable({
        data: dataMemo,
        columns: columnsMemo,
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: {
            sorting,
            globalFilter,
            rowSelection
        },
        onGlobalFilterChange: setGlobalFilter,
        onRowSelectionChange: setRowSelection,
        globalFilterFn: fuzzyFilter
    });

    const pageSize = table.getState().pagination.pageSize;
    const pageIndex = table.getState().pagination.pageIndex;
    const headerGroups = table.getHeaderGroups();
    const allRows = table.getRowModel().rows;
    const footerGroups = table.getFooterGroups();
    
    return (
        <div className="react-table-container">
            <div>
                {(search || download) && (
                    <div className="search">
                        {search && (
                            <Search
                                className="search-input"
                                onChange={(value) => setGlobalFilter(String(value))}
                                placeholder="Search"
                                value={globalFilter ?? ''}
                            />
                        )}
                        {download && (
                            <Download
                                fileName={`${download}.csv`}
                                footerEnabled={footer}
                                table={table}
                            />
                        )}
                    </div>
                )}
            </div>
            {(search || download) && <div className="divider" />}

            <div className="react-table-scroll-container">
                <table className="react-table">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    const { id, colSpan } = header;
                                    const align = header?.column?.columnDef?.meta?.align;

                                    return (
                                        <th className={`align-${align}`} colSpan={colSpan} key={id}>
                                            {header.isPlaceholder ? null : (
                                                <>
                                                    <span
                                                        {...{
                                                            className: header.column.getCanSort()
                                                                ? `cursor-pointer select-none display-inline`
                                                                : '',
                                                            onClick:
                                                                header.column.getToggleSortingHandler()
                                                        }}
                                                    >
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                    </span>
                                                    <span
                                                        {...{
                                                            className: header.column.getCanSort()
                                                                ? `cursor-pointer select-none display-inline ${
                                                                      {
                                                                          asc: 'fa fa-sort-asc',
                                                                          desc: 'fa fa-sort-desc'
                                                                      }[
                                                                          header.column.getIsSorted() as string
                                                                      ] ?? 'order fa fa-sort'
                                                                  }`
                                                                : '',
                                                            onClick:
                                                                header.column.getToggleSortingHandler()
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>

                    {allRows.length === 0 ? (
                        <tbody>
                            <tr className="">
                                <td className="react-table-no-data" colSpan={10}>
                                    There is no data to display
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <>
                            <tbody>
                                {allRows.map((row) => {
                                    const { className, onClick } = rowsMeta || {};

                                    return (
                                        (
                                            <tr
                                                className={classNames(
                                                    className,
                                                    onClick ? 'clickable-row' : ''
                                                )}
                                                key={row.id}
                                                onClick={() => {
                                                    onClick?.(row);
                                                }}
                                            >
                                                {row.getVisibleCells().map((cell, i) => {
                                                    const align =
                                                        cell?.column?.columnDef?.meta?.align ||
                                                        'left';
                                                    const onClick =
                                                        cell.column.columnDef.meta?.onCellClick;
                                                    const wrapText =
                                                        cell?.column?.columnDef?.meta?.wrapText || '';

                                                    const cellWidth =
                                                        cell?.column?.columnDef?.['cellWidth'];
                                                    return (
                                                        <td
                                                            className={classNames(
                                                                `align-${align} ${wrapText}`,
                                                                onClick ? 'cell-clickable' : ''
                                                            )}
                                                            key={cell.id}
                                                            onClick={() => {
                                                                onClick && onClick(cell, i);
                                                            }}
                                                            style={{
                                                                ...(cellWidth
                                                                    ? { minWidth: cellWidth }
                                                                    : {})
                                                            }}
                                                        >
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        ) || null
                                    );
                                })}
                            </tbody>

                            {footer && (
                                <tfoot>
                                    <tr>
                                        {/* We only have one footer group for now, so just take the first index */}
                                        {footerGroups[0].headers.map((header) => {
                                            const { column } = header;
                                            const { columnDef } = column;
                                            const {
                                                id,
                                                footer = '',
                                                meta
                                            } = columnDef;
                                            const align = meta?.align ?? 'left';
                                            
                                            return (
                                                <td className={`align-${align}`} key={id}>
                                                    {typeof footer === 'function' ? footer({ table, header, column }) : footer }
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </tfoot>
                            )}
                        </>
                    )}
                </table>
            </div>

            {pageSizes && (
                <>
                    <div className="divider" />

                    <div className="table-pagination">
                        <div className="page-size-container">
                            <span>Rows: </span>
                            <MenuTrigger
                                Trigger={(ref) => {
                                    return (
                                        <ButtonRipple className="page-size-button" ref={ref}>
                                            <span>{paginationLabel(pageSize)}</span>
                                            <span className="icon material-icons-outlined">
                                                arrow_drop_up
                                            </span>
                                        </ButtonRipple>
                                    );
                                }}
                                menu={{
                                    className: '',
                                    listItems: pageSizes.map((size) => {
                                        return {
                                            label: paginationLabel(size),
                                            onClick: () => {
                                                table.setPageSize(size);
                                            }
                                        };
                                    })
                                }}
                            />
                        </div>

                        {Math.ceil(
                            table.getPrePaginationRowModel().rows.length /
                                table.getState().pagination.pageSize
                        ) > 1 && (
                            <PaginationButtons
                                goToPage={table.setPageIndex}
                                pageIndex={pageIndex}
                                pageSize={table.getState().pagination.pageSize}
                                totalRows={table.getPrePaginationRowModel().rows.length}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
export default ReactTable;
