import { default as classNames, default as cx } from 'classnames';
import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import Checkbox from '../Checkbox/Checkbox';
import Toolbar from './components/Toolbar/Toolbar';
import CustomSizePerPageDropdown from './CustomSizePerPageDropdown';
import ExportCSVButton from './ExportCSVButton';
import './Table.scss';

// Constants
// =================================================================================================
const exportTypes = {
    CSV: { extension: 'csv', separator: ',' },
    TSV: { extension: 'tsv', separator: '\t' }
};

const defaultSizesPerPage = [10, 50, 250, 1000, 1000000];

// Helper Functions
// =================================================================================================
const columnClassNameFormat = (fieldValue, row, rowIdx, colIdx, customFormatter, extras) => {
    if (typeof customFormatter === 'function') {
        return customFormatter(fieldValue, row, rowIdx, colIdx, extras);
    } else {
        const { props, header, i, currentCell } = extras;

        const keys = props.clickedCell ? Object.keys(props.clickedCell.rowData) : null;

        if (keys) {
            if (
                row[keys[0]] === props.clickedCell.rowData[keys[0]] &&
                row[keys[1]] === props.clickedCell.rowData[keys[1]] &&
                props.clickedCell.colNumber === colIdx
            ) {
                return `active ${header.clickable ? 'clickable-cell' : ''} 
                ${props.hoverColours ? `hover-${props.hoverColours[i]}` : ''}`;
            }
        }
        return cx([
            header.clickable && 'clickable-cell',
            props.hoverColours ? `hover-${props.hoverColours[i]}` : null
        ]);
    }
};

const customMultiSelect = ({ checked, rowIndex, onChange }) => {
    return (
        <div className="checkbox-personalised">
            <Checkbox checked={checked} onChange={onChange} />
            <label htmlFor={'checkbox' + rowIndex} />
        </div>
    );
};

// Redux
// =================================================================================================
const mapStateToProps = (state) => ({
    dateFormat: get(state.user.settings, 'date'),
    currencyCode: get(state, 'user.settings.currency', 'GBP'),
    locale: get(state, 'i18n.locale', 'gb')
});

const mapDispatchToProps = (dispatch) => ({});

// Types
// =================================================================================================
export interface ITableHeaders {
    // required
    text: string;
    // optional
    icon?: object;
    hidden?: boolean;
    field?: string;
    width?: string | number;
    formatter?: (cell: any, row: any) => any;
    download?: (value: any) => any;
    csv?: Function;
    clickable?: boolean;
    dataAlign?: string;
    headerAlign?: string;
    sortFunc?: Function;
    dataSort?: boolean;
}

type Props = {
    // required
    headers: Array<ITableHeaders>;
    tableData: Array<Object>;
    // optional
    onRowClick?: Function;
    striped?: boolean;
    bordered?: boolean;
    download?: string; // download file name, also behaves as a falsy toggle
    resize?: boolean;
    pagination?: boolean;
    search?: boolean;
    dataSort?: boolean;
    toolBar?: string;
    hidden?: boolean;
    sizesPerPage?: number[]; // undefined = use defaults above; single number = hide selector + use number; multi numbers = show selector + use first number initiailly
    sortName?: string;
    sortOrder?: 'asc' | 'desc';
    rowClasses?: Array<string>;
    hoverColours?: Array<string>;
    customColour?: Function;
    clickHoverType?: string;
    clickedCell?: any;
    footerData?: Array<Array<Object>>;
    handleSearch?: Function;
    extraToolbarRow?: any;
    afterSearch?: Function;
    colClassFormatter?: Function;
    checkboxPrefill?: Array<string | number>;
    checkboxCallback?: Function;
    checkboxClearCallback?: Function;
    checkboxAllCallback?: Function;
    selectedCheckboxes?;
};

export type ExportType = {
    extension: string;
    separator: string;
};

// Component
// =================================================================================================
const Table: React.FC<Props> = (props) => {
    const [exportType, setExportType] = useState<ExportType>(exportTypes.CSV);
    const sizePerPageOptions =
        props.sizesPerPage && props.sizesPerPage.length ? props.sizesPerPage : defaultSizesPerPage;
    const [sizePerPage, setSizePerPage] = useState<number>(sizePerPageOptions[0]);
    const [sizeSelectorOpen, setSizeSelectorOpen] = useState<boolean>(false);

    const showFooter = props.footerData ? true : false;
    const [customSortName, setCustomSortName] = useState('');
    const [customSortOrder, setCustomSortOrder] = useState('');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(
        props.checkboxPrefill ? props.checkboxPrefill : []
    );
    const [currentCell, setCurrentCell] = useState(props.clickedCell || null);

    useEffect(() => {
        setCustomSortName(props.sortName || '');
        setCustomSortOrder(props.sortOrder || '');
    }, [props.sortName, props.sortOrder]);

    useEffect(() => {
        if (
            props.headers &&
            props.clickedCell &&
            props.headers[props.clickedCell.colId] &&
            props.headers[props.clickedCell.colId].clickable === true
        ) {
            setCurrentCell(props.clickedCell);
        }
    }, [props.clickedCell, props.headers]);

    // useEffect(() => {
    //     setCurrentCell(overwriteCurrentCell);
    // }, [overwriteCurrentCell]);

    useEffect(() => {
        setSelectedCheckboxes((props.selectedCheckboxes || []).map(({ name }) => name));
    }, [props.selectedCheckboxes]);

    const renderSizePerPageDropDown = () => {
        return (
            <CustomSizePerPageDropdown
                setSizePerPage={setSizePerPage}
                setSizeSelectorOpen={setSizeSelectorOpen}
                sizePerPage={sizePerPage}
                sizePerPageOptions={sizePerPageOptions}
                sizeSelectorOpen={sizeSelectorOpen}
            />
        );
    };

    const renderCSVButton = (onClick) => (
        <ExportCSVButton
            exportTypes={exportTypes}
            onClick={onClick}
            setExportType={setExportType}
        />
    );

    const showDownload = props.download && get(props.tableData, 'length') >= 1;
    const showResize = props.resize && get(props.tableData, 'length') > sizePerPageOptions[0];

    let downloadFileName;
    let sizePerPageDropDown;

    if (showDownload) {
        downloadFileName = `${props.download}.${exportType.extension}`;
    }

    if (showResize && sizePerPageOptions.length > 1) {
        sizePerPageDropDown = () => renderSizePerPageDropDown();
    }

    const onSortChange = (sortName, sortOrder) => {
        setCustomSortName(sortName);
        setCustomSortOrder(sortOrder);
    };

    const options: any = {
        sizePerPageDropDown,
        onRowClick: props.onRowClick,
        sortIndicator: props.dataSort === false ? false : true,
        paginationSize: 5,
        hidePageListOnlyOnePage: true,
        clearSearch: false,
        alwaysShowAllBtns: false,
        withFirstAndLast: false,
        exportCSVBtn: renderCSVButton,
        exportCSVSeparator: exportType.separator,
        sizePerPage: sizePerPage,
        sortName: customSortName || undefined,
        sortOrder: customSortOrder || 'desc',
        onSortChange: onSortChange,
        ...(props.toolBar && {
            toolBar: (libraryProps) => (
                <Toolbar
                    checkboxAllCallback={props.checkboxAllCallback}
                    checkboxCallback={props.checkboxCallback}
                    checkboxClearCallback={props.checkboxClearCallback}
                    extraToolbarRow={props.extraToolbarRow}
                    libraryProps={libraryProps}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    type={props.toolBar}
                />
            )
        }),
        sizePerPageList: sizePerPageOptions,
        hideSizePerPage: !showResize,
        ...(typeof props.handleSearch === 'function' && { onSearchChange: props.handleSearch }),
        ...(typeof props.afterSearch === 'function' && { afterSearch: props.afterSearch })
    };

    return (
        <BootstrapTable
            bordered={props.bordered}
            className={classNames(props.toolBar ? 'table-toolbar' : '')}
            csvFileName={downloadFileName}
            data={props.tableData}
            exportCSV={showDownload}
            footer={showFooter}
            footerData={props.footerData}
            hover
            keyField="key"
            options={options}
            pagination={props.pagination}
            search={props.search}
            selectRow={
                props.checkboxCallback
                    ? {
                          mode: 'checkbox',
                          className: 'has-custom-checkbox',
                          onSelect: function (row, isChecked) {
                              if (props.checkboxCallback) {
                                  props.checkboxCallback(row, isChecked);
                              }
                              if (isChecked) {
                                  setSelectedCheckboxes([...selectedCheckboxes, row.name]);
                              } else {
                                  var newArray = [...selectedCheckboxes];
                                  newArray = newArray.filter((item) => item !== row.name);
                                  setSelectedCheckboxes(newArray);
                              }
                          },
                          clickToSelect: true,
                          customComponent: customMultiSelect,
                          selected: selectedCheckboxes
                      }
                    : {}
            }
            tableStyle={{ overflow: 'auto' }}
            trClassName={function (arg1) {
                const customColourClass = props.customColour
                    ? props.customColour(arg1)
                    : props.clickHoverType === 'row'
                    ? ' clickable-row '
                    : '';
                const checkBoxClass = props.checkboxCallback ? ' multi-select ' : '';

                return `${customColourClass} ${checkBoxClass} ${
                    props.rowClasses ? props.rowClasses.join(' ') : ''
                }`.trim();
            }}
            version="4"
        >
            {props.headers.map((header, i) => {
                const { formatter, download } = header;

                return (
                    <TableHeaderColumn
                        className={header.hidden ? 'u-display-none' : ''}
                        columnClassName={(fieldValue, row, rowIdx, colIdx) =>
                            columnClassNameFormat(
                                fieldValue,
                                row,
                                rowIdx,
                                colIdx,
                                props.colClassFormatter,
                                {
                                    props,
                                    currentCell,
                                    header,
                                    i
                                }
                            )
                        }
                        csvFormat={download || formatter}
                        dataAlign={header.dataAlign || 'left'}
                        dataField={header.field || header.text.toLowerCase()}
                        dataFormat={formatter}
                        dataSort={header.dataSort === false ? false : true}
                        footerAlign={header.dataAlign || 'left'}
                        headerAlign={header.headerAlign || 'left'}
                        hover
                        key={header.text}
                        sortFunc={header.sortFunc || undefined}
                        width={String(header.width)}
                    >
                        <span title={header.text}>
                            {header.icon && (
                                <i
                                    className={`fa fa-${get(header, 'icon.symbol')} ${get(
                                        header,
                                        'icon.colour'
                                    )} mr-1`}
                                />
                            )}
                            {header.text}
                        </span>
                    </TableHeaderColumn>
                );
            })}
        </BootstrapTable>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
