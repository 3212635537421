import { Tag } from '../../index.types';
import { Moment } from 'moment';
export enum DashboardActionTypes {
    SET_CURRENT_CODES = 'DB_SET_CURRENT_CODES',
    SET_CURRENT_REFERRAL = 'DB_SET_CURRENT_REFERRAL',
    SET_CURRENT_TAG = 'DB_SET_CURRENT_TAG',
    SET_CURRENT_DATES = 'DB_SET_CURRENT_DATES',
    SET_DYNAMIC_KEY = 'DB_SET_DYNAMIC_KEY',
    SET_REPORT_DATES = 'DB_SET_REPORT_DATES',
    UPDATE_INITIATED = 'UPDATE_INITIATED',
    UPDATE_ENDED = 'UPDATE_ENDED',
    FOCUS_IN_CONTEXT = 'FOCUS_IN_CONTEXT',
    FOCUS_OUT_CONTEXT = 'FOCUS_OUT_CONTEXT',
    CODE_REVIEW_DATA = 'CONTEXT_CODE_REVIEW_DATA',
    SET_MULTI_TAGS = 'SET_MULTI_TAGS'
}

export const setMultiTagsAction = (tags: Tag[]) => ({
    type: DashboardActionTypes.SET_MULTI_TAGS,
    tags
});

export const setTagAction = (tag: Tag) => ({
    type: DashboardActionTypes.SET_CURRENT_TAG,
    tag
});

export const setReferralAction = ({channels, sources, publishers}) => ({
    type: DashboardActionTypes.SET_CURRENT_REFERRAL,
    payload: {channels, sources, publishers}
});

export const setCodesAction = (codes) => {
    return ({
        type: DashboardActionTypes.SET_CURRENT_CODES,
        payload: codes
    });
};

export const setDatesAction = ({ startDate, endDate }: { startDate: string; endDate: string }) => ({
    type: DashboardActionTypes.SET_CURRENT_DATES,
    payload: { date: { startDate, endDate } }
});

export const setAdvOptsAction = (advancedOptions: Object) => {
    let activeDKs = {};

    Object.keys(advancedOptions).forEach((key) => {
        const select = advancedOptions[key];

        if (select.type === 'dynamicKey') {
            activeDKs[key] = select.value;
        }
    });

    return {
        type: DashboardActionTypes.SET_DYNAMIC_KEY,
        payload: { activeDKs }
    };
};

export const setReportDatesAction = ({
    startDate,
    endDate
}: {
    startDate: Moment;
    endDate: Moment;
}) => ({
    type: DashboardActionTypes.SET_REPORT_DATES,
    date: { startDate, endDate }
});

export const UpdateInitiatedAction = () => ({
    type: DashboardActionTypes.UPDATE_INITIATED
});
export const UpdateEndedAction = () => ({
    type: DashboardActionTypes.UPDATE_ENDED
});

export const focusInContextAction = () => ({
    type: DashboardActionTypes.FOCUS_IN_CONTEXT
});
export const focusOutOfContextAction = () => ({
    type: DashboardActionTypes.FOCUS_OUT_CONTEXT
});
