import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector as uSel } from 'react-redux';
import { Alert, ModalBody } from 'reactstrap';
import { isEmpty, isNil } from 'ramda';
import { history } from 'redux/store';

import { API } from 'services/api.service';
import Spinner from 'components/Spinner/Spinner';
import { IAppStoreState as S } from 'redux/interfaces';
import { ReactComponent as LogoIcon } from '../../../../assets/img/brand/logo-icon.svg';
import { forgotInitAction, loginAction } from '../../../../redux/actions';
import Credentials from './components/Credentials/Credentials';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import UpdatePassword from './components/UpdatePassword/UpdatePassword';
import BackButton from 'components/BackButton/BackButton';
import './LoginModal.scss';

const LoginModal = (): JSX.Element => {
    const dispatch = useDispatch();
    const loggingIn = uSel((state: S) => state.auth.loggingIn);
    const loggedIn = uSel((state: S) => state.auth.loggedIn);
    const checkingStatus = uSel((state: S) => state.auth.checkingStatus);
    const isErrorGlobal = uSel((state: S) => state.auth.isError);
    const errorMessageGlobal = uSel((state: S) => state.auth.errorMessage);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [mfaNeeded, setMFANeeded] = useState(false);
    const [isForgot, setIsForgot] = useState(false);
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);

    const isLoading = loggingIn || isRequestInProgress || checkingStatus;
    const isReset = window.location.pathname === '/account/reset-password';
    const isError = !!errorMessage.length;
    const isSuccess = !!successMessage.length;

    // On error, update component
    useEffect(() => {
        setIsRequestInProgress(false);
        if (errorMessageGlobal === 'You must supply a valid MFA code') setMFANeeded(true);
        if (!isNil(errorMessageGlobal) && !isEmpty(errorMessageGlobal))
            setErrorMessage('Login failed; You have entered invalid email address or password.');
    }, [isErrorGlobal, errorMessageGlobal]);

    // Tell component we're not logging in
    useEffect(() => setIsRequestInProgress(false), [loggingIn, checkingStatus]);

    useEffect(() => {
        if (email.length) {
            let timerOut = setTimeout(() => {
                var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (!mailformat.test(email)) {
                    setErrorMessage('This is not a valid email address');
                    setDisableSubmitButton(true);
                    return false;
                } else setDisableSubmitButton(false);
            }, 500);
            return () => clearTimeout(timerOut);
        }
    }, [email]);

    return (
        <>
            <div className="modal-header">
                <div className="modal-header-content">
                    {isForgot && (
                        <BackButton
                            floating
                            onClick={() => {
                                setIsForgot(false);
                                setSuccessMessage('');
                            }}
                        />
                    )}

                    {loggedIn || isLoading || checkingStatus ? <Spinner /> : <LogoIcon />}
                    <h1>
                        {(() => {
                            if (loggedIn || isLoading || checkingStatus) return 'Logging in..';
                            if (isForgot || isReset) return 'Set password';
                            return 'Sign in';
                        })()}
                    </h1>
                </div>
            </div>

            {!loggedIn && !isLoading && !checkingStatus && (
                <ModalBody>
                    <Alert
                        color={isError ? 'danger' : 'success'}
                        style={{
                            opacity: isError || isSuccess ? 1 : 0,
                            transition: 'all 0.3s',
                            maxHeight: isError || isSuccess ? '100px' : '0px',
                            display: 'block',
                            marginBottom: isError || isSuccess ? '16px' : '0px',
                            padding: isError || isSuccess ? '12px 20px' : '0px 20px'
                        }}
                    >
                        {errorMessage || successMessage}
                    </Alert>

                    {(() => {
                        if (isReset)
                            return (
                                <UpdatePassword
                                    onChange={() => {
                                        setErrorMessage('');
                                        setSuccessMessage('');
                                    }}
                                    onSubmit={(resetCode, newPassword, confirmPassword) => {
                                        API.user()
                                            .auth()
                                            .resetPassword({
                                                token: resetCode,
                                                password: newPassword,
                                                'password-confirm': confirmPassword
                                            })
                                            .then(() => history.push('/'))
                                            .catch((err) => {
                                                setErrorMessage(err.errorMessage);
                                            });
                                    }}
                                />
                            );
                        if (isForgot)
                            return (
                                <ForgotPassword
                                    email={email}
                                    onChange={(value) => {
                                        setErrorMessage('');
                                        setSuccessMessage('');
                                        setEmail(value);
                                    }}
                                    onSubmit={() => {
                                        dispatch(
                                            forgotInitAction({
                                                email,
                                                domain: window.location.host
                                            })
                                        );
                                        setSuccessMessage('Password reset email sent');
                                    }}
                                    disableSubmitButton={disableSubmitButton}
                                />
                            );

                        return (
                            <Credentials
                                email={email}
                                loginCallback={({ email, password, mfa, remember }) => {
                                    dispatch(loginAction({ email, password, mfa, remember }));
                                }}
                                mfaNeeded={mfaNeeded}
                                password={password}
                                setEmail={(e) => {
                                    setErrorMessage('');
                                    setEmail(e);
                                }}
                                setForgot={(e) => {
                                    setErrorMessage('');
                                    setIsForgot(e);
                                }}
                                setMFANeeded={setMFANeeded}
                                setPassword={setPassword}
                                disableSubmitButton={disableSubmitButton}
                            />
                        );
                    })()}
                </ModalBody>
            )}
        </>
    );
};

export default LoginModal;
