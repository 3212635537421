import Toast from 'components/Toast/Toast';
import { compose, map, values } from 'ramda';
import React, { useEffect } from 'react';
import 'react-bootstrap-table/dist//react-bootstrap-table-all.min.css';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import {
    addModalAction,
    getCurrenciesListAction,
    getUserMFAActiveAction,
    setUserSettingsAction
} from 'redux/actions';
import { IAppStoreState as S } from 'redux/interfaces';
import { getStyle } from 'utils';
import { changePasswordAction, changePasswordInitialiseAction } from '../redux/account.actions';
import AccountAbout from './About';
import './Account.scss';
import AccountContact from './Contact';
import AccountMFA from './MFA';
import AccountPassword from './Password';
import AccountProfileHeader from './ProfileHeader';
import AccountSettings from './Settings';

const Account = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: S) => state.user);
    const userSettings = useSelector((state: S) => state.user?.settings);
    const passwordChange = useSelector((state: S) => state.account.changePassword);
    const currencyList = useSelector((state: S) => state.currencyList);

    useEffect(() => {
        dispatch(getUserMFAActiveAction());
        dispatch(getCurrenciesListAction());
    }, []);

    return (
        <div className="account">
            <Helmet>
                <title>intent.ly - Account</title>
            </Helmet>
            <Row>
                <Col>
                    <AccountProfileHeader/>
                </Col>
            </Row>

            <Row>
                <Col md="6" xs="12">
                    <Row>
                        <Col xs="12">
                            <AccountPassword
                                changePasswordAction={({ current, new: next, confirm }) =>
                                    dispatch(changePasswordAction({ current, new: next, confirm }))
                                }
                                changePasswordInitialiseAction={() => {
                                    dispatch(changePasswordInitialiseAction());
                                }}
                                passwordChange={passwordChange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <AccountContact />
                        </Col>
                    </Row>
                </Col>
                <Col md="6" xs="12">
                    <Row>
                        <Col xs="12">
                            <AccountSettings
                                currenciesApi={
                                    currencyList.map(({ code, symbol }) => ({
                                        value: code,
                                        label: `${code} (${symbol})`,
                                        symbol
                                    }))
                                }
                                saveUserSettings={(settings) => {
                                    dispatch(setUserSettingsAction(settings));
                                }}
                                userSettings={userSettings}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <AccountMFA
                                addModalAction={(modalType, content, modalButtons) => {
                                    dispatch(addModalAction(modalType, content, modalButtons));
                                }}
                                user={user}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <AccountAbout
                                addModalAction={(modalType, content, modalButtons) => {
                                    dispatch(addModalAction(modalType, content, modalButtons));
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {user.uploadImage && user.uploadImage.error && (
                <Toast>
                    <>
                        <span style={{ color: getStyle('--danger') }}>Error: </span>
                        {user.uploadImage.error}
                    </>
                </Toast>
            )}
        </div>
    );
};

export default Account;
