import { useState, useEffect, useRef } from 'react';

export function useJsonFetch<T>(url: string) {
    const isMounted = useRef(true);
    const [data, setData] = useState<T | null>(null);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        fetch(url)
            .then(x => x.json())
            .then(y => {
                if (isMounted.current) {
                    setData(y);
                }
            })
            .catch(() => {
                if (isMounted.current) {
                    setData(null);
                }
            });
    }, [url, setData]);

    return data;
}
