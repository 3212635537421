import React from 'react';
import { useDispatch, useSelector as uSel } from 'react-redux';
import { IAppStoreState as S } from 'redux/interfaces';
import ReactTable from 'components/ReactTable/ReactTable';
import { generateColumns } from './helpers';
import SpinnerCard from 'components/Spinner/SpinnerCard';
import { Card, CardBody } from 'reactstrap';
import { addModalAction } from 'redux/actions';

const CodesBaskets = (): JSX.Element => {
  const dispatch = useDispatch();
  const isLoading = uSel((state: S) => state?.smartercodes?.api?.codeDetails?.isLoading || false);
  const attempts = uSel(
    (state: S) => state?.smartercodes?.codes?.currentCode?.attempts || []
  );
  const tagName = uSel(
    (state: S) => state?.dashboard?.context?.tag?.tag_name
  );

  const downloadName = `Baskets${tagName ? ` - ${tagName}` : ''}`;
  const data = attempts.map((attempt) => ({ ...attempt, basketView: 'shopping_cart' }));

  if (isLoading) return <SpinnerCard />;

  return (
    <Card className="card-table">
      <CardBody>
        <ReactTable
          columns={generateColumns(
            (cell) => {
              const row = cell.row.original;
              const basket = row.basket;
              dispatch(addModalAction('basket', { basket }, null));
            }
          )}
          data={data}
          download={downloadName}
          pagination
          search
          sort={{ id: 'basketValue', desc: true }}
        />
      </CardBody>
    </Card>
  );
};

export default CodesBaskets;
