import React from 'react';
import { useSelector as uSel } from 'react-redux';
import { IAppStoreState as S } from 'redux/interfaces';
import {useJsonFetch} from '../WorldMap/useJsonFetch';

import SpinnerCard from 'components/Spinner/SpinnerCard';
import ReactTable from 'components/ReactTable/ReactTable';
import { generateColumns } from './helpers';

type Country = Record<string, {
    code: string,
    name: string;
}>;

const CodesGeographicalBreakdown = (): JSX.Element => {
    const countryNames = useJsonFetch<Country>('/world-110m-country-names.json') ?? {};
    const isLoading = uSel((state: S) => state?.smartercodes?.api?.codeDetails?.isLoading || false);
    const countryData = uSel(
        (state: S) => state?.smartercodes?.codes?.currentCode?.countries || []
    );
    const tagName = uSel(
      (state: S) => state?.dashboard?.context?.tag?.tag_name
    );

    const downloadName = `Geolocation${tagName ? ` - ${tagName}` : ''}`;

    const countryDataFinal = countryData.map((item: { countryCode: string }) => {
        const { name } = Object.values(countryNames).find(
          ({ code }) => code === item.countryCode
        ) ?? { name: 'Unknown' };
        return { ...item, countryName: name };
    });

    if (isLoading) {
        return <SpinnerCard />;
    }
    return (
        <ReactTable
            columns={generateColumns()}
            data={countryDataFinal}
            download={downloadName}
            pagination
            search
            sort={{ id: 'attempts', desc: true }}
        />
    );
};

export default CodesGeographicalBreakdown;
