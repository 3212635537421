import findIndex from 'lodash.findindex';
import { SmarterCodesActionTypes } from 'Dashboard/views/SmarterCodes/redux/types';
import { Tag } from 'index.types';
import { AuthActionTypes, UserActionTypes } from 'redux/actions';
import { readQueryString } from 'utils';
import { DashboardActionTypes } from './dashboard.actions';
import {
    getInitialActiveDKs,
    getInitialEndDate,
    getInitialStartDate
} from './utils/getInitialValues';
import moment from 'moment';

export interface IContext {
  tag: Tag;
  startDate: string | null;
  endDate: string | null;
  revenueLost: number;
  activeAdvOpts: Array<Object>;
  activeDKs: Object;
  multiTags: Tag[];
  channels: { id: number; name: string; }[]
  sources: { id: number; name: string; }[]
  publishers: { id: number; name: string; }[]
  codes: { id: number; name: string; }[]
}

export interface IDashboardState {
    access: {
        highlevel: boolean;
        smartercodes: boolean;
        account: boolean;
    };
    context: IContext;
    dim: {
        requestInProgress: boolean;
        focusInContext: boolean;
        verdict: boolean;
    };
    url: {
        tagid?: number;
        startdate?: string;
        enddate?: string;
        code?: string;
        dynamickeys?: Array<Object>;
    };
}

const initialState: IDashboardState = {
    access: {
        highlevel: false,
        smartercodes: false,
        account: false
    },
    dim: {
        requestInProgress: false,
        focusInContext: false,
        verdict: false
    },
    context: {
        tag: {} as Tag,
        startDate: getInitialStartDate(),
        endDate: getInitialEndDate(),
        revenueLost: 0,
        activeAdvOpts: [],
        activeDKs: getInitialActiveDKs(),
        multiTags: [],
        channels: [],
        sources: [],
        publishers: [],
        codes: []
    },
    url: {}
};

// TODO: Add URL from context generator
export function dashboard(state: IDashboardState = initialState, action): IDashboardState {
    switch (action.type) {
        case DashboardActionTypes.SET_CURRENT_TAG:
            localStorage.setItem('tag_index', action.tag.tag_index);
            return {
                ...state,
                dim: {
                    ...state.dim,
                    requestInProgress: true
                },
                context: {
                    ...state.context,
                    tag: action.tag
                },
                url: {
                    ...state.url,
                    tagid: action.tag.tag_index
                }
            };
        case DashboardActionTypes.SET_CURRENT_REFERRAL:
            const {channels, sources, publishers} = action.payload;
            return {
                ...state,
                dim: {
                    ...state.dim,
                    requestInProgress: true
                },
                context: {
                    ...state.context,
                    channels,
                    sources,
                    publishers
                }
            };
        case DashboardActionTypes.SET_CURRENT_CODES:
            const codes = action.payload;
            return {
                ...state,
                dim: {
                    ...state.dim,
                    requestInProgress: true
                },
                context: {
                    ...state.context,
                    codes
                }
            };
        case DashboardActionTypes.SET_CURRENT_DATES:
            return {
                ...state,
                dim: {
                    ...state.dim,
                    requestInProgress: true
                },
                context: {
                    ...state.context,
                    startDate: action.payload.date.startDate,
                    endDate: action.payload.date.endDate
                },
                url: {
                    ...state.url,
                    startdate: moment(action.payload.date.startDate).toISOString(),
                    enddate: moment(action.payload.date.endDate).toISOString()
                }
            };
        case DashboardActionTypes.SET_DYNAMIC_KEY:
            return {
                ...state,
                dim: {
                    ...state.dim,
                    requestInProgress: true
                },
                context: {
                    ...state.context,
                    activeDKs: action.payload.activeDKs
                }
            };

        case DashboardActionTypes.FOCUS_IN_CONTEXT:
            return {
                ...state,
                dim: {
                    ...state.dim,
                    focusInContext: true,
                    verdict: true
                }
            };
        case DashboardActionTypes.FOCUS_OUT_CONTEXT:
            //When the focus may be off context, the dimming is applied by all other states that cause dimming
            return {
                ...state,

                dim: {
                    ...state.dim,
                    focusInContext: false,
                    verdict: state.dim.requestInProgress // add other dim requests here e.g. || event
                }
            };
        case DashboardActionTypes.UPDATE_INITIATED:
            return {
                ...state,

                dim: {
                    ...state.dim,
                    requestInProgress: true,
                    verdict: true
                }
            };
        case DashboardActionTypes.UPDATE_ENDED:
            return {
                ...state,
                dim: {
                    ...state.dim,
                    requestInProgress: false,
                    verdict: state.dim.focusInContext
                }
            };
        case UserActionTypes.GET_DATA_SUCCESS:
            //Find if the user has 1 tag allowed

            let userTags = action.user.smartercodes_tags;
            let userHas0Tag = userTags && userTags.length === 0;
            let userHas1Tag = userTags && userTags.length === 1;

            let defaultTag = {} as Tag;
            if (userHas1Tag) {
                // If only 1 tag, then it will be the set tag and the user cannot access areas to change it
                defaultTag = userTags[0];
            } else {
                //if multiple tags, check if they have set a preffered default tag
                if (action.user.settings && action.user.settings.defaultTagIndex) {
                    //IF set, find if this exists in their allowed tags.
                    let matchedTags = userTags.filter(
                        (tag) => tag.tag_index === action.user.settings.defaultTagIndex
                    );
                    if (matchedTags.length === 1) {
                        //If we find it, then use as a default
                        defaultTag = matchedTags[0];
                    }
                } // If the user has not set a preferred tag, then we can leave tag unselected (empty object)
                else {
                    if (!userHas0Tag) {
                        // If user has selected a tag recently, we'll retrieve that from localStorage to use as the default
                        // Otherwise, just use the first item in userTags array
                        const qsTagIndex = readQueryString('tid');
                        let localTagIndex: null | number = null;

                        if (qsTagIndex && qsTagIndex !== 'undefined') {
                            // Query string - linked session
                            localTagIndex = qsTagIndex ? parseInt(qsTagIndex, 10) : null;
                        } else {
                            // Local storage - restore previous session
                            const rawTagIndex = localStorage.getItem('tag_index');
                            localTagIndex = rawTagIndex ? JSON.parse(rawTagIndex) : null;
                        }

                        let tagPosition = localTagIndex
                            ? findIndex(userTags, (tag) => tag.tag_index === localTagIndex)
                            : 0;

                        if (tagPosition === -1) {
                            tagPosition = 0;
                        }

                        defaultTag = userTags[tagPosition];
                    }
                }
            }

            return {
                ...state,
                access: {
                    ...state.access,
                    highlevel: !userHas1Tag && !userHas0Tag,
                    smartercodes: !userHas0Tag
                },
                context: {
                    ...state.context,
                    tag: defaultTag
                },
                url: {
                    tagid: defaultTag.tag_index // will be undefined if no tag selected anyway
                }
            };
        case SmarterCodesActionTypes.SET_CODE:
            return {
                ...state,
                url: {
                    ...state.url,
                    code: action.code
                }
            };
        case SmarterCodesActionTypes.CLEAR_CODE:
            state.url.code && delete state.url.code;
            return {
                ...state
            };
        case AuthActionTypes.LOGOUT_SUCCESS:
            return {
                ...initialState
            };
        case AuthActionTypes.STATUS_YES:
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                access: {
                    ...state.access,
                    account: true
                }
            };
        case DashboardActionTypes.CODE_REVIEW_DATA:
            return {
                ...state,
                context: {
                    ...state.context,
                }
            };
        case DashboardActionTypes.SET_MULTI_TAGS:
            return {
                ...state,
                dim: {
                    ...state.dim,
                    requestInProgress: true
                },
                context: {
                    ...state.context,
                    multiTags: action.tags
                },
                url: {
                    ...state.url
                }
            };

        default:
            return state;
    }
}
