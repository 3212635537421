import React, { Fragment } from 'react';
import WorldMap from '../WorldMap/WorldMap';
import Legend from '../WorldMap/Legend';
import { Card, CardHeader, CardBody } from 'reactstrap';
import SpinnerCard from 'components/Spinner/SpinnerCard';
import { connect } from 'react-redux';
import { IAppStoreState } from 'redux/interfaces';
import get from 'lodash.get';

export type ApiCountry = {
    countryCode: string;
    attempts: number;
    key: number;
};

const mapStateToProps = (state: IAppStoreState) => ({
    countriesData: get(state.smartercodes.codes.currentCode, 'countries'),
    codeName: get(state.smartercodes.codes.currentCode, 'combinedCurrentEntry.name'),
    totalAttempts: get(state.smartercodes.codes.currentCode, 'combinedCurrentEntry.totalAttempts'),
    isLoading: state.smartercodes.api.codeDetails.isLoading
});

const mapDispatchToProps = dispatch => ({});

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

const CodesMap: React.FC<Props> = ({ countriesData, codeName, totalAttempts, isLoading }) => {
    if (isLoading) {
        return <SpinnerCard />;
    }

    if (!countriesData) return null;

    return (
        <div className="world-map">
            <Legend />
            <WorldMap apiData={countriesData} totalAttempts={totalAttempts} />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CodesMap);
