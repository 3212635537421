import { OverlaysState, OverlayYearlyStats } from '../overlays.reducers';
import moment from 'moment';

export type YearlyStatsChartData = {
    dates: string[];
    impressions: number[];
    closed: number[];
    shown: number[];
    engagements: number[];
};

export function apiOverlayYearlyStats(state: OverlaysState, rawApiData: OverlayYearlyStats) {
    const { output } = rawApiData;
    const dates = Object.keys(output).sort((a, b) => Date.parse(a) - Date.parse(b));

    const chartData: YearlyStatsChartData = {
        dates: [],
        impressions: [],
        closed: [],
        shown: [],
        engagements: []
    };

    dates.forEach(date => {
        const item = output[date];

        const campaigns = Object.values(item);

        const totals = {} as { [key: string]: number };

        const relevantKeys = ['script_access', 'engagement', 'overlay_shown'];

        for (const campaign of campaigns) {
            for (const key of relevantKeys) {
                if (!totals[key]) totals[key] = 0;

                totals[key] += campaign[key] || 0;
            }
        }

        chartData.dates.push(date);
        chartData.impressions.push(totals.script_access);
        chartData.engagements.push(totals.engagement);
        chartData.closed.push(totals.overlay_shown - totals.engagement);
        chartData.shown.push(totals.overlay_shown);
    });

    const newState: OverlaysState = {
        ...state,
        yearlyStats: { chartData }
    };

    return newState;
}
