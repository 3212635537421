import React from 'react';

import SpinnerCard from 'components/Spinner/SpinnerCard';
import ReactTable from 'components/ReactTable/ReactTable';
import { generateColumns } from './helpers';

import '../../Validity.scss';

type Props = {
    types: any;
    data: any[];
    isLoading?: boolean;
    breakdownAnchor?: string;
    downloadName: string;
};

const ValidityTableSmall = ({ isLoading, data, downloadName }: Props): JSX.Element => {
    if (isLoading) {
        return <SpinnerCard />;
    }
    return <ReactTable
      columns={generateColumns()}
      data={data}
      download={downloadName}
      search
      sort={{ id: 'attempts', desc: true }}
    />;
};

export default ValidityTableSmall;
